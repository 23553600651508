import React from 'react';
import Select from '../../components/Select';
import CategoriaRespuestaDataService from "../../Services/PreguntaBrecha/CategoriaRespuesta.Service";
import Spinner from '../../components/Spinner';

const default_item_respuesta_categoria = {
    idRespuestaCategoria: 0,
    idSesion: 0,
    idRespuesta: 0,
    idCategoria: 0,
    idUsuario: 0,
    select_categoria: {
        value: 0,
        label: ""
    },
    respuesta: {
        idRespuesta: 0,
        idSesion: 0,
        idPregunta: 0,
        idUsuario: 0,
        idEtapa: 0,
        respuesta: ""
    },
    // sesion: {
    //     idSesion: 0,
    //     idInicio: 0,
    //     idEtapa: 0,
    //     link: "",
    //     token: "",
    //     activo: false
    // }
};

class ControlCategoriaRespuesta extends React.Component {
    constructor(props) {
        super(props);
        const etapa = this.props.match.params.etapa === undefined ? 0 : this.props.match.params.etapa;
        this.state = {
            loading: false,
            etapa: etapa,
            data: [],
            dataSelectCategorias: [],
            tabActive: 0,
            buttonsEnabled: 1,
            messageSpinner: "Espere un momento...",
            encuestaEnabled: true,
            infoModal: false
        }
    }

    // Handlers

    GoPrevRespuesta = async () => {
        let tabActive = this.state.tabActive;
        let dataLength = this.state.data.length;

        if ((tabActive - 1) >= (0)) {
            this.setState({ tabActive: tabActive - 1 })
        } else {
            this.setState({ tabActive: dataLength - 1 })
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }

    GoNextRespuesta = async () => {
        let tabActive = this.state.tabActive;
        let dataLength = this.state.data.length;

        if ((tabActive + 1) <= (dataLength - 1)) {
            this.setState({ tabActive: tabActive + 1 })
        } else {
            this.setState({ tabActive: 0 })
        }
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 10);
    }

    GoToNav = async (index) => {
        this.setState({ tabActive: index })
    }

    //
    componentDidMount = async () => {
        if (await this.getEtapaNoActivaOFinalizada()) {
            await this.GetCategorias();
            let haRespondido = await this.getCategoriasYaAsignadas(localStorage.getItem('idUsuario'), this.state.etapa);
            if (!haRespondido) {
                await this.GetAllRespuestas();
            }
        }
    }
    //

    // Métodos

    GetCategorias = async () => {
        let combo = [];
        await CategoriaRespuestaDataService.getCategorias().then(response => {
            response.data.forEach(cat => {
                if (cat.esDefault) {
                    let item = { value: cat.idCategoria, label: cat.nombre };
                    combo.push(item)
                }
            })
            combo.unshift({ value: 0, label: "Seleccione una categoría" });
            if (combo.length > 0) {
                this.setState({ dataSelectCategorias: combo })
            }
        });
    }

    getCategoriasYaAsignadas = async (idUsuario, idEtapa) => {
        let haRespondido = false;
        await CategoriaRespuestaDataService.getCategoriasYaAsignadas(idUsuario, idEtapa).then(response => {
            haRespondido = response.data.length > 0 ? true : false;
        })
        if (haRespondido) {
            this.setState({ messageSpinner: `Su respuesta ha sido enviada. Por favor espere las indicaciones del facilitador para dar clic en "Continuar"`, encuestaEnabled: false, loading: true })
        }
        return haRespondido;
    }

    getEtapaNoActivaOFinalizada = async () => {
        this.setState({ loading: true })
        let etapaActiva = true;
        await CategoriaRespuestaDataService.getEtapaNoActivaOFinalizada(this.state.etapa)
            .then(response => {
                if (!response.data.activo || response.data.finalizado) {
                    etapaActiva = false
                    this.props.history.push("/home/" + localStorage.getItem("keyJwt"));
                }
            })
        return etapaActiva;
    }

    GetAllRespuestas = async () => {
        let dataCategoriaRespuesta = [];
        let idUsuario = localStorage.getItem('idUsuario');
        await CategoriaRespuestaDataService.getAllRespuestasCategoria(idUsuario, this.state.etapa).then(response => {
            console.log(response.data)
            // Se crean objetos de la forma Categoria Respuesta (Entidad)
            response.data.forEach(respuesta => {
                let item = {
                    idRespuestaCategoria: 0,
                    idSesion: 0,
                    idRespuesta: 0,
                    idCategoria: 0,
                    idUsuario: 0,
                    select_categoria: {
                        value: 0,
                        label: "Seleccione una categoría"
                    },
                    respuesta: {
                        idRespuesta: 0,
                        idSesion: 0,
                        idPregunta: 0,
                        idUsuario: 0,
                        idEtapa: 0,
                        respuesta: ""
                    },
                    otro: null
                };
                item.idRespuesta = respuesta.idRespuesta;
                item.idSesion = respuesta.idSesion;
                item.respuesta.respuesta = respuesta.respuesta;
                item.idUsuario = localStorage.getItem('idUsuario');

                dataCategoriaRespuesta.push(item);
            });

            if (dataCategoriaRespuesta.length > 0) {
                this.setState({ data: dataCategoriaRespuesta, loading: false })
            }
        }).catch(e => {
            this.setState({
                loading: false
            });
            console.log(e);
        });
    }

    validarRespuestas = () => {
        let data = this.state.data;
        let valido = true;

        data.forEach(x => {
            if (x.select_categoria.value === 0)
                valido = false
            if (x.select_categoria.label === "Otro") {
                if (x.otro === null || x.otro === "")
                    valido = false
            }
        })

        return valido;
    }

    Finalizar = async () => {
        let data = this.state.data;
        this.setState({ loading: true })
        await CategoriaRespuestaDataService.create(data).then(response => {
            this.setState({ buttonsEnabled: 0, loading: false })
            this.props.history.push("/home/" + localStorage.getItem("keyJwt"));
        })
    }
    //
    render() {
        return (
            <div className="page-container">
                <Spinner show={this.state.loading} message={this.state.messageSpinner} />
                <div className="title"><h1>ABP</h1></div>
                <div className="subtitle"><p>Vincule cada respuesta a la categoría que más se asemeje dando clic en el menú desplegable</p></div>
                <div className="SliderQuestionContainer">
                    <div className="StatusQuestionNav">
                        <ul>
                            {
                                this.state.data.map((respuesta, index) => {
                                    return (
                                        <li onClick={() => { this.GoToNav(index) }} className={respuesta.select_categoria.value !== 0 ? "success" : ""}></li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="SliderQuestion">
                        <div className="LeftArrow">
                            <button onClick={(e) => { this.GoPrevRespuesta() }} type="button"><i className="fas fa-caret-left"></i></button>
                        </div>
                        <div className="Screen">
                            <div className="TabsContainer">
                                <div className="TabsContent">
                                    {
                                        this.state.data.map((res, i) => {
                                            return (
                                                <div key={i} className={`Tab ${i === this.state.tabActive ? "active" : ""}`}>
                                                    <div className="questions-container">
                                                        <div className="question">
                                                            <label htmlFor="">Respuesta otorgada</label>
                                                            <div className="description text-justify">
                                                                <p className="no-border">
                                                                    {res.respuesta.respuesta}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="question">
                                                            <label htmlFor="">Categoría</label>
                                                            <div className="description text-justify">
                                                                <Select
                                                                    divClass=""
                                                                    selectplaceholder="Seleccione"
                                                                    selectValue={res.select_categoria}
                                                                    selectIsSearchable={false}
                                                                    selectoptions={this.state.dataSelectCategorias}
                                                                    selectOnchange={(selected) => {
                                                                        let data = this.state.data;
                                                                        data[i].idCategoria = selected.value;
                                                                        data[i].select_categoria = selected;
                                                                        this.setState(prevState => ({
                                                                            ...prevState,
                                                                            data: data
                                                                        }));
                                                                        // Scroll hacia abajo
                                                                        if (selected.label === "Otro") {
                                                                            let b = document.querySelector('body');
                                                                            if (b) {
                                                                                setTimeout(() => {
                                                                                    window.scrollTo(0, b.scrollHeight)
                                                                                }, 10);
                                                                            }
                                                                        }
                                                                    }}
                                                                    selectclassNamePrefix="selectReact__value-container"
                                                                    spanClass="error"
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            res.select_categoria.label === "Otro" ?
                                                                <div className="question">
                                                                    <label htmlFor="">Especifique</label>
                                                                    <div className="description text-justify">
                                                                        <textarea placeholder="¿Qué categoría según usted se relaciona más a la respuesta?" value={res.otro} onChange={(e) => {
                                                                            let data = this.state.data;
                                                                            data[i].otro = e.currentTarget.value;
                                                                            this.setState(prevState => ({
                                                                                ...prevState,
                                                                                data: data
                                                                            }));
                                                                        }}></textarea>
                                                                    </div>
                                                                </div> : ""
                                                        }
                                                    </div>
                                                    {
                                                        this.state.encuestaEnabled ?
                                                            <button onClick={() => {
                                                                if (!this.validarRespuestas())
                                                                    this.GoNextRespuesta();
                                                                else
                                                                    this.Finalizar();
                                                            }} className={`btn ${!this.validarRespuestas() ? "primary" : "danger"}`} type="button">{!this.validarRespuestas() ? "Siguiente" : "Finalizar y Enviar respuestas"}</button>
                                                            :
                                                            <button type="button" disabled className="btn">Respuestas enviadas</button>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="RightArrow">
                            <button onClick={(e) => { this.GoNextRespuesta() }} type="button"><i className="fas fa-caret-right"></i></button>
                        </div>
                    </div>
                </div>
                <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                    <div className="modalMessage">
                        <div className="modalHeader">
                            <p>ABP Virtual</p>
                            <small>Sistema de consulta participativa</small>
                        </div>
                        <div className="modalBody">
                            <p>El sistema de consulta participativa permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                            <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                        </div>
                        <div className="modalFooter">
                            <button type="button" onClick={() => { this.setState({ infoModal: false }) }} className="btn primary">Cerrar mensaje</button>
                        </div>
                    </div>
                </div>
                <div className="leftActions">
                    <button type="button" onClick={() => { this.setState({ infoModal: true }) }}><i className="fas fa-info" type="button"></i></button>
                </div>
            </div>
        )
    }
}

export default ControlCategoriaRespuesta;