import React from 'react';
import './Spinner.css';

const Spinner = ({ show, message = "Espere un momento..." }) => {

    if (show) {
        removeNone();
        return (
            <div className="subloader active">
                <div className="relative"></div>
                <p>{message}</p>
                <button onClick={() => { window.location.reload() }} className="btn primary"><i className="fa fa-undo"></i> Continuar</button>
            </div>
        )
    }
    else
        return (
            <div className="subloader">
                <div className="relative"></div>
            </div>
        )
}
function removeNone() {
    setTimeout(() => {
        if (document.querySelector(".reloadLate"))
            document.querySelector(".reloadLate").classList.remove('none')
    }, 10000);
}

export default Spinner;