import React from 'react';
import PreguntasAbiertasService from "../../Services/PreguntasAbiertas/PreguntasAbiertas.Service";
import Spinner from '../../components/Spinner';
const fieldsConst = {
    idRespuesta: 0,
    idSesion: 0,
    idPregunta: 0,
    idUsuario: 0,
    idEtapa: 0,
    respuesta: '',

    activo: true
}
const preguntaConst = {
    idPregunta: 0,
    idEtapa: 0,
    orden: 0,
    nombre: '',
    respondido: false,
    activo: true
}
class ControlPreguntasAbiertas extends React.Component {
    constructor(props) {
        super(props);
        const etapa = this.props.match.params.etapa === undefined ? 0 : this.props.match.params.etapa;
        this.state = {
            etapa: etapa,
            loading: true,
            fields: fieldsConst,
            pregunta: preguntaConst,
            messageSpinner: "Espere un momento...",
            error: '',
            infoModal: false
        }
    }

    // Handlers
    handlerChangeRespuesta = async (value) => {
        this.setState(prevState => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                respuesta: value
            }
        }));
    }
    //
    componentDidMount = async () => {
        if (await this.getEtapaNoActivaOFinalizada()) {
            if (this.state.etapa != 0)
                this.getPregunta(this.state.etapa, localStorage.getItem("idUsuario"));
        }
    }
    //

    // Métodos

    getEtapaNoActivaOFinalizada = async () => {
        let etapaActiva = true;
        await PreguntasAbiertasService.getEtapaNoActivaOFinalizada(this.state.etapa)
            .then(response => {
                if (!response.data.activo || response.data.finalizado) {
                    etapaActiva = false
                    this.props.history.push("/home/" + localStorage.getItem("keyJwt"));
                }
            })
        return etapaActiva;
    }

    getPregunta = async (id, user) => {

        this.setState({ loading: true });
        await PreguntasAbiertasService.getV2(id, user)
            .then(response => {
                let pregunta = this.state.pregunta;
                let msgspinner = "";
                pregunta = response.data;
                console.log(pregunta);
                if (pregunta.respondido) {
                    msgspinner = `Su respuesta ha sido enviada. Por favor espere las indicaciones del facilitador para dar clic en "Continuar"`;
                }
                this.setState({
                    pregunta: pregunta,
                    loading: pregunta.respondido,
                    messageSpinner: msgspinner
                });
            })
            .catch(e => {
                this.setState({
                    loading: false
                });
                console.log(e);
            });
    }
    PostPregunta = async (data) => {
        this.setState({ loading: true });
        await PreguntasAbiertasService.create(data)
            .then(response => {
                let res = this.state.fields;
                res = response.data;
                this.setState({
                    fields: res,
                    loading: false
                });
            })
            .catch(e => {
                this.setState({
                    loading: false
                });
                console.log(e);
            });
    }
    Comenzar = () => {
        let data = this.state.fields;
        if (data.respuesta != "") {
            data.idPregunta = this.state.pregunta.idPregunta;
            data.idUsuario = localStorage.getItem("idUsuario");
            data.idEtapa = this.state.pregunta.idEtapa;

            console.log(data, "--->>");


            this.PostPregunta(data);
            this.props.history.goBack();
        }
        else {
            this.setState({ error: "Ingrese una respuesta." });
            setInputErrors();
        }
    }
    Recargar = () => {
        document.location.reload(true);
    }
    //
    render() {
        return (
            <div className="page-container">
                <Spinner show={this.state.loading} message={this.state.messageSpinner} />
                {/* <div className="title"><h1>ABP</h1></div> */}
                <div className="subtitle" style={{ marginTop: "45px" }}><p>Por favor responda a la pregunta de acuerdo a las conclusiones de su mesa. Al finalizar, de clic en “Enviar respuesta”</p></div>
                <div className="TabsContainer">
                    <div className="TabsContent">
                        <div className={`Tab active`} data-ref="1">
                            <div className="questions-container">
                                <div className="question">
                                    <label htmlFor="">Pregunta</label>
                                    <div className="description text-justify">
                                        <p className="no-border">{this.state.pregunta.nombre}</p>
                                    </div>
                                </div>
                                {
                                    !this.state.pregunta.respondido ?
                                        <div className="question">
                                            <label htmlFor="">Respuesta</label>
                                            <div className="description text-justify">
                                                <textarea placeholder="Escriba aquí su respuesta" onChange={(e) => { this.handlerChangeRespuesta(e.currentTarget.value) }} value={this.state.fields.respuesta} />
                                            </div>
                                        </div> : ""
                                }
                                <span className="error">
                                    {this.state.error || ""}
                                </span>
                            </div>
                            {
                                this.state.pregunta.respondido ?
                                    "" :
                                    <button onClick={() => { this.Comenzar() }} className="btn primary" type="button">Enviar respuesta</button>
                            }
                        </div>
                    </div>
                </div>
                <div className={`modalContainer ${this.state.infoModal ? "active" : ""}`}>
                    <div className="modalMessage">
                        <div className="modalHeader">
                            {/* <p>ABP Virtual</p> */}
                            <p>Sistema de consulta participativa</p>
                        </div>
                        <div className="modalBody">
                            <p>El sistema de consulta participativa permite obtener datos muestra de los usuarios que participen haciendo uso de un token cifrado. Las respuestas que el usuario o participante de la sesión registre en esta aplicación, se almacena en una base de datos que posteriormente el equipo de administración procesará.</p>
                            <p>El sistema le brinda un usuario temporal, de manera que no se almacenan otros datos personales de su ordenador. Si tiene problemas con la aplicación, siéntase con la libertad de dirigirse al facilitador del evento  a través del chat de Zoom.</p>
                        </div>
                        <div className="modalFooter">
                            <button type="button" onClick={() => { this.setState({ infoModal: false }) }} className="btn primary">Cerrar mensaje</button>
                        </div>
                    </div>
                </div>
                <div className="leftActions">
                    <button type="button" onClick={() => { this.setState({ infoModal: true }) }}><i className="fas fa-info" type="button"></i></button>
                </div>
            </div>
        )
    }
}

function setInputErrors() {
    let body = document.querySelector('body');
    if (body) {
        let inputs = body.querySelectorAll('textarea');
        inputs.forEach(x => {
            x.classList.add("error-detected");
        });
        setTimeout(() => {
            inputs.forEach(x => {
                x.classList.remove("error-detected");
            });
        }, 3000);
    }
}

export default ControlPreguntasAbiertas;