import axios from "axios";
import AuthLogin from "../Utils/AuthLogin";

let auth = new AuthLogin();

let instance = axios.create({
     //baseURL: 'https://localhost:44340/api',
    // baseURL: 'https://abpvirtual.com.mx/api/api',
    baseURL : 'https://abpmorantapi.azurewebsites.net/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use(
    async config => {
    const token = auth.keyJwt();
    config.headers.Authorization =  token;

    return config;
});

export { instance as default };